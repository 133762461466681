import api from '~/services/api';
import { CreatePedidoProps } from '../../types/services';
import { ResponseFormatted } from '../../types';

const createPedido = async (
  formData: CreatePedidoProps,
): Promise<
  ResponseFormatted<{
    cod_seq_pedido: number;
    num_pedido: number;
    tipo_status: number;
  }>
> => {
  const { data } = await api.post<
    ResponseFormatted<{
      cod_seq_pedido: number;
      num_pedido: number;
      tipo_status: number;
    }>
  >('/create-pedido', formData);
  return data;
};

export const pedidoService = { createPedido };
